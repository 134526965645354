import React, { useState, useRef, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import tr from 'date-fns/locale/tr';
import { format, parseISO, isValid } from 'date-fns';

registerLocale('tr', tr); 

const DateInput = ({ id, placeholder, onChange, iconOnClick, value }) => {
  const [startDate, setStartDate] = useState(new Date());
  const refDatePicker = useRef(null);


  useEffect(() => {
    if (value) {
      const parsedDate = parseISO(value);
      if (isValid(parsedDate)) {
        setStartDate(parsedDate);
        onChange(parsedDate);
      }
    } else {
      setStartDate(new Date());
      onChange(new Date());
    }
  }, [value, onChange]);

  const openDatePicker = () => {
    refDatePicker.current.setOpen(true); 
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    if (onChange) {
      onChange(date);
    }
  };

  const clearDate = () => {
    setStartDate(null);
    if (onChange) {
      onChange(null);
    }
  };

  return (
    <div className="input-wrapper form-floating mb-2 date-input">
      <DatePicker
        portalId="root-portal"
        ref={refDatePicker}
        selected={startDate}
        onChange={handleDateChange}
        id={id}
        locale="tr"
        popperPlacement="bottom-start"
        dateFormat="dd/MM/yyyy"
        className="form-control"
      />
      <label htmlFor={id} className={startDate ? 'hide-label' : ''}>
        {placeholder}
      </label>
      <div className='input-icon one' onClick={openDatePicker}>
        <span className={`calendar icon`}></span> 
      </div>
      {iconOnClick && (
        <div className='input-icon' onClick={clearDate}>
          <span className={`gray-delete icon`}></span> 
        </div>
      )}
      <div 
        className="input-overlay" 
        onClick={openDatePicker} 
        style={{ position: 'absolute', top: 0, left: 0, right: 46, bottom: 0, cursor: 'pointer' }} 
      />
    </div>
  );
};

export default DateInput;
