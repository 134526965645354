import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import api from '../../services/api';


const PageTop = ({ title, backBtnValue, rightBtnOnClick, rightBtnValue, isBeta }) => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    
    const goBack = () => {
      navigate(-1); 
    };
    
    const logout = async () => {
        try {
            const session =  localStorage.getItem('userId');
            const response = await api.get_user_info({
                session: session
            });
            localStorage.clear();
            navigate('/login');
        } catch (error) {
            console.log('hata:', error);
        }
    };

    
    return (
        <div className={`page-inf-wrapper`}>
            <div className='left'>
                {backBtnValue == 'Geri' && (
                    <a className='back-btn' onClick={goBack}>
                        <span className='back-icon'></span>
                        <span className='back-txt'>
                            {backBtnValue}
                        </span>
                    </a>
                )}
                {backBtnValue == 'Çıkış' && (
                    <a className='back-btn logout' onClick={logout}>
                        <span className='back-txt'>
                            {backBtnValue}
                        </span>
                    </a>
                )}
            </div>
            <div className='center'>
                <h5 className="page-title">
                    { title }
                </h5>
                {isBeta && (
                    <span className='beta-tag'>Beta</span>
                )}
            </div>
            <div className='right'>
                {rightBtnValue && (
                    <a onClick={rightBtnOnClick}>
                        {rightBtnValue}
                    </a>
                )}
            </div>
        </div>
    );
};

export default PageTop;