import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/styles/style.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
    }).catch(function(err) {
    });
  });
}
