import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import IconButton from '../IconButton';
import { toAdvancedTitle_Case } from '../../utils/function.js';

const SingleDepartment = ({ allowedVats, order, name, kdv, onClick, onDeleteClick, onNameChange, onKdvChange, isSelected, isEditing: initialIsEditing }) => {
  const [isEditing, setIsEditing] = useState(initialIsEditing);
  const [inputValue, setInputValue] = useState(name);
  const [selectedKdv, setSelectedKdv] = useState(kdv);
  const [enabledKdvs, setEnabledKdvs] = useState([]);
  const nameInputRef = useRef(null);
  const kdvItems = ["%0", "%1", "%10", "%20"];
  
  useEffect(() => {
    if (name.trim() === "" && !isEditing) {
      setIsEditing(true);
    }
  }, [name, isEditing]);

  useEffect(() => {
    if (isSelected && isEditing && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [isSelected, isEditing]);

  useEffect(() => {
    setIsEditing(initialIsEditing);
    setInputValue(name);
  }, [initialIsEditing, name]);

  useEffect(() => {
    setSelectedKdv(kdv);
  }, [kdv]);

  useEffect(() => {
    setEnabledKdvs(allowedVats ? allowedVats.split(',').map(vat => `%${vat}`) : []);
  }, [allowedVats]);

  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
    onClick();
  };

  const handleNameEdit = (e) => {
    const newValue = toAdvancedTitle_Case(e.target.value);
    setInputValue(newValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); 
      handleSave();
    } else if (e.key === 'Escape') {
      setInputValue(name);
      setIsEditing(false);
    }
  };

  const handleSave = () => {
    onNameChange(inputValue);
    if (inputValue.trim() !== "") {
      setIsEditing(false); 
    }
  };

  const handleBlur = () => {
    if (inputValue.trim() === "") {
      setInputValue(name);
    } else {
      handleSave();
    }
  };

  const handleKdvClick = (e, newKdv) => {
    e.stopPropagation();
    if (enabledKdvs.includes(newKdv)) {
      setSelectedKdv(newKdv);
      onKdvChange(newKdv);
    }
  };

  return (
    <div 
      className={`single-department-item edit ${isSelected ? 'clicked' : ''}`} 
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <div className='department-row'>
        <div className='department'>
          <div className='left'>
            <div className='order'>{order}</div>
            {isEditing ? (
              <input
                className='item-name'
                ref={nameInputRef}
                value={inputValue}
                onChange={handleNameEdit}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
              />
            ) : (
              <div className='item-name' onClick={handleEditClick}>{name}</div>
            )}
          </div>
          <div className='right'>
            <IconButton iconClass="trash-icon" onClick={(event) => {
              event.stopPropagation();
              onDeleteClick();
            }} />
          </div>
        </div>
        <div className='kdv'>
          <span>KDV</span> <b>{selectedKdv}</b>
        </div>
      </div>
      {isSelected && (
        <div className={`department-row`}> 
          <div className='kdv-wrapper'>
            {kdvItems.map(item => (
              <div key={item} className={`kdv-item ${selectedKdv === item ? 'choosen' : ''} ${enabledKdvs.includes(item) ? '' : 'disabled-item'}`}
                    onClick={(e) => handleKdvClick(e, item)}>
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleDepartment;