import React, { useState, useEffect, useRef } from 'react';
import './style.css';

const Textarea = ({ onBlur, value, onChange, id, input_trim, placeholder, iconName, iconOnClick, maxLength, hasError, onBeforeInput, readOnly, autoFocus, textCenter, required  }) => {
  
  

  const handleChange = (e) => {
    // const newValue = e.target.value.replace(/\s+/g, ' ').trimStart();
    // const newValue = e.target.value.replace(/\s+/g, ' ');
    onChange({ target: { value: e.target.value } });
  };

  const handleChangeWithTrim = (e) => {
    const newValue = e.target.value.replace(/\s+/g, ' ').trimStart();
    onChange({ target: { value: newValue } });
  };

  return (
    <div className={`textarea-wrapper form-floating mb-2 text-center ${required === 'true' ? 'required' : ''}`}>
      <textarea  
        className={`form-control ${hasError ? 'has-error' : ''} ${textCenter === 'true' ? 'text-center' : ''}`}
        id={id}
        value={value}
        onChange={!input_trim ? handleChangeWithTrim : handleChange }
        placeholder={placeholder}
        maxLength={maxLength}
        onBeforeInput={onBeforeInput}
        readOnly={readOnly}
        autoFocus={autoFocus}
        required = {required}
        onBlur={onBlur}
      />
      <label htmlFor={id}>
        {placeholder}
      </label>
      {iconName && <div className='input-icon' onClick={iconOnClick}>
        <span className={`${iconName} icon`}></span> 
      </div>}
    </div>
  );
};

export default Textarea;
