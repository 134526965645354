import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import SingleDepartment from '../../components/SingleDepartment';
import PageTop from '../../components/PageTop';
import api from '../../services/api';
import { toAdvancedTitleCase, refreshSession } from '../../utils/function.js';

const Departments = () => {
  const [departments, setDepartments] = useState([]);
  const [selectedDept, setSelectedDept] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [showWarning, setShowWarning] = useState(false);
  const errorRef = useRef(null);

  useEffect( () => {
    const storedDepartments = localStorage.getItem('departments');
    if (storedDepartments) {
      const parsedDepartments = JSON.parse(storedDepartments);
      const departmentsWithPercentage = parsedDepartments.map(dept => ({
        ...dept,
        kdv: `%${dept.kdv}`,
        allowedVats: dept.allowedVats || "20"
      }));
      setDepartments(departmentsWithPercentage);
    } else {
        const gibInfoRaw = localStorage.getItem('gib_info');
        const gibInfo = gibInfoRaw ? JSON.parse(gibInfoRaw) : null;
        if (gibInfo && Array.isArray(gibInfo.nACEFaaliyetKoduveTanimi) && gibInfo.nACEFaaliyetKoduveTanimi.length > 0) {
          // console.log('gibInfo.nACEFaaliyetKoduveTanimi', gibInfo.nACEFaaliyetKoduveTanimi)
          loadDepartments(gibInfo.nACEFaaliyetKoduveTanimi);
        } else {
          console.error('NACE faaliyet kodları yüklenemedi veya gib_info tanımsız.');
        }
      }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleAddDepartment();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [departments]); 

  const loadDepartments = async (activities) => {
    let foundAny = false;
    let firstNaceCode = activities.length > 0 ? activities[0].faaliyetKodu : null;
    let savedNaceCode = null;
    let initialDepartments = [];

    for (const activity of activities) {
      const response = await fetchDepartmentData(activity.faaliyetKodu);

      if (response && response.get_department_list && response.get_department_list.item) {
        const items = Array.isArray(response.get_department_list.item) 
            ? response.get_department_list.item 
            : [response.get_department_list.item];

        const allowedVats = response.allow_vat_list ? response.allow_vat_list.split(',') : [];

        items.forEach(dept => {
          const kdv = `%${dept.rate}`;
          const allowedVats = response.allow_vat_list ? response.allow_vat_list.split(',') : [];

          const department = {
            name: dept.name,
            kdv: allowedVats.includes(dept.rate) ? kdv : '%20',
            allowedVats: allowedVats.join(',') 
          };
          initialDepartments.push(department);
          setDepartments(prev => [...prev, department]);
          foundAny = true;
        });

        if (!savedNaceCode) {
          savedNaceCode = activity.faaliyetKodu;
          localStorage.setItem('saved_nace_code', savedNaceCode);
        }
      }
    }

    if (!foundAny && firstNaceCode) {
      localStorage.setItem('saved_nace_code', firstNaceCode);
    }

    if (!foundAny && departments.length === 0) {
      handleAddDepartment();
    }

    localStorage.setItem('initialDepartments', JSON.stringify(initialDepartments));
  };

  const fetchDepartmentData = async (naceCode) => {
    const userId = localStorage.getItem('userId');
    try {
      const response = await api.get_department_by_nace({
        session: userId,
        nace_code: naceCode
        // nace_code: 475103
      });
      if(response.error ){
        if(response.error.number === '51'){
          return null;
        } else if(response.error.number === '10'){
          await refreshSession(); 
          return fetchDepartmentData(naceCode);
        }
      }
      // console.log('response', response)
      return response;
    } catch (error) {
      console.error('Departman bilgileri alınırken bir hata oluştu:', error);
      return null;
    }
  };
  

  const handleClick = (index) => {
    setSelectedDept(index); 
    setError('');
    setShowWarning(true);
  };

  useEffect(() => {
    const clickedExists = document.querySelector('.single-department-item.clicked') !== null;
    setShowWarning(clickedExists);
  }, [departments]);

  const handleNavigate = () => {
    if (departments.length > 8) {
      setError('En fazla 8 adet departman tanımlayabilirsiniz.');
      return; 
    };

    const allFieldsFilled = departments.every(dept => dept.name.trim() && dept.kdv.trim() && dept.kdv !== '%');
    if (!allFieldsFilled) {
      setError('Lütfen tüm departmanlar için isim ve KDV değerlerini doldurunuz. Tüm KDV alanları dolu olmalıdır.');
      return;
    }
    
    if (allFieldsFilled && departments.length > 0) {
      const departmentsToSave = departments.map(({ name, kdv, allowedVats }) => ({
        name,
        kdv: parseInt(kdv.replace('%', ''), 10),
        allowedVats
      }));
      localStorage.setItem('departments', JSON.stringify(departmentsToSave));

      const initialDepartments = JSON.parse(localStorage.getItem('initialDepartments') || '[]');
      const normalizedInitialDepartments = initialDepartments.map(dept => ({
        ...dept,
        kdv: parseInt(dept.kdv.replace('%', ''), 10)
      }));

      const currentDepartmentsForComparison = departments.map(({ name, kdv, allowedVats }) => ({
        name,
        kdv: parseInt(kdv.replace('%', ''), 10),
        allowedVats
      }));

      const isDifferent = JSON.stringify(normalizedInitialDepartments) !== JSON.stringify(currentDepartmentsForComparison);
      
      if (isDifferent) {
        console.log('Departmanlar değiştirildi:');
        console.log('İlk yüklenen departmanlar:', normalizedInitialDepartments);
        console.log('Güncel departmanlar:', currentDepartmentsForComparison);
        localStorage.setItem('departmentChanged', '1');
      } else {
        console.log('Departmanlar değişmedi:');
        console.log('İlk yüklenen departmanlar:', normalizedInitialDepartments);
        console.log('Güncel departmanlar:', currentDepartmentsForComparison);
        localStorage.setItem('departmentChanged', '0');
      }

      navigate('/yazar-kasalar');
    } else {
      setError('Lütfen tüm departmanlar için isim ve KDV değerlerini doldurunuz.');
    }
  };

  const handleNameChange = (index, newName) => {
    const updatedDepartments = [...departments];
    updatedDepartments[index].name = toAdvancedTitleCase(newName);
    setDepartments(updatedDepartments);
  };

  const handleKdvChange = (index, newKdv) => {
    const updatedDepartments = [...departments];
    const department = updatedDepartments[index];
    const allowedVats = department.allowedVats.split(',').map(vat => `%${vat}`);
    
    if (allowedVats.includes(newKdv)) {
      department.kdv = newKdv;
    } else {
      department.kdv = allowedVats[0] || '%20';
    }
    
    setDepartments(updatedDepartments);
  };

  const handleAddDepartment = () => {
    // const hasEmptyDepartment = departments.some(dept => dept.name.trim() === "" || dept.kdv.trim() === "%");
    const hasInvalidDepartment = departments.some(dept => dept.name.trim() === "" || !dept.kdv.trim() || dept.kdv === "%");

    if (hasInvalidDepartment) {
      setError('Lütfen eksik departman bilgilerini tamamlayınız. Tüm isim ve KDV alanları dolu olmalıdır. Sonrasında tekrar departman tanımlayabilirsiniz.');
    } else {
      const updatedDepartments = departments.map(dept => ({
        ...dept,
        editing: false
      }));

      const newDepartment = {
        name: "",
        kdv: "%20",
        editing: true,
        allowedVats: "20" 
      };

      setDepartments([...updatedDepartments, newDepartment]);
      setSelectedDept(departments.length); 
      setError('');
    }
  };

  const handleDeleteDepartment = (index) => {
    setDepartments(currentDepartments => {
      const newDepartments = currentDepartments.filter((_, i) => i !== index);
      return newDepartments;
    });
    setError('');
  };

  const resetDepartments = () => {
    setDepartments([]);
    setError('');
  }

  useEffect(() => {
    if (error) {
      errorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [error]); 

  return (
    <div className='page-wrapper container'>
      <div className='page-top'>
        <PageTop 
          backBtnValue="Geri" 
          title="Departmanlar" 
          rightBtnValue="Sıfırla"
          rightBtnOnClick={resetDepartments}
        />
        <div className='page-content'>
          <div className='departments'>
            {departments.map((department, index) => (
              <SingleDepartment
                key={index}
                order={index + 1}
                name={toAdvancedTitleCase(department.name)}
                kdv={department.kdv}
                onClick={() => handleClick(index)}
                onDeleteClick={() => handleDeleteDepartment(index)}
                onNameChange={(newName) => handleNameChange(index, newName)}
                onKdvChange={(newKdv) => handleKdvChange(index, newKdv)}
                isSelected={selectedDept === index}
                isEditing={department.editing}
                allowedVats={department.allowedVats}
              />
            ))}
          </div>
          <div className='txt-btn-wrapper'>
            <a onClick={handleAddDepartment} className='blue-txt-btn'>
              Yeni Departman Tanımla
            </a>
          </div>
          {showWarning && (
            <div className='text-center mt-3'>
              <p className='text-sm mt-2'>
                Tavsiye edilen değerleri lütfen kontrol ediniz. Hatalı KDV oran girişleri ile ilgili Hugin sorumluluk kabul etmemektedir.
              </p>
            </div>
          )}
          {error && <div className='error-message' ref={errorRef}>{error}</div>}
        </div>
      </div>

      <div className='page-bottom'>
        <div className='btn-wrapper'>
          <Button value="Devam" viewType="btn-blue" onClick={handleNavigate} />
        </div>
      </div>
    </div>
  );
};

export default Departments;