import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './pages/01-LGN01';
import TaxNumberPage from './pages/01-CRM-KUR01';
import VoucherInformationsPage from './pages/01-CRM-KUR02';
import AuthorizedInformationPage from './pages/01-CRM-KUR03';
import DepartmentsPage from './pages/01-CRM-KUR04';
import MyDevicesPage from './pages/01-CRM-KUR05';
import CashRegisters from './pages/01-CRM-KUR05V2';
import DeviceToBeInstalledPage from './pages/02-CRM-KUR06';
import BillingInformationsPage from './pages/01-MUS05';
import InstallationReportPage from './pages/02-CRM-KUR07';
import SalesInquiryReportPage from './pages/02-CRM-KUR08';
import SalesInquiryReportPageAlternative from './pages/02-CRM-KUR09';
import api from './services/api';
import { decryptPassword } from './utils/hash';
import BillingInformations from './pages/01-MUS05';

console.log(process.env)

function use100vh() {
  const setFullHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    setFullHeight();
    window.addEventListener('resize', setFullHeight);
    return () => window.removeEventListener('resize', setFullHeight);
  }, []);

  return;
}
function App() {
  const [devices, setDevices] = useState([]);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [clickedDevices, setClickedDevices] = useState(() => {
    const storedDevices = localStorage.getItem('clickedDevices');
    return storedDevices ? JSON.parse(storedDevices) : [];
  });

  const refreshSession = async () => {
    const username = localStorage.getItem('username');
    const encryptedPassword = localStorage.getItem('password');
    
      if (username && encryptedPassword) {
        try {
          const password = decryptPassword(encryptedPassword);
          const response = await api.login ({
                user_name: username,
                password: password
            });

            if (response && response.error && response.error.number === '10') {
                console.log('Hata', response.error.message);
                localStorage.removeItem('userId');
            } else if (response && response.error && response.error.number === '0') {
                localStorage.setItem('userId', response.id);
                console.log('Session yenilendi');
            }
        } catch (error) {
            console.log('Session yenilenirken hata', error.message);
            localStorage.removeItem('userId');
        }
    } else {
        localStorage.removeItem('userId');
    }
  }

  use100vh();

  useEffect(() => {
    const intervalId = setInterval(refreshSession, 1000 * 60 * 20); // 20 dk
    return () => clearInterval(intervalId); 
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/okc-satis-sorgulama" element={<ProtectedRoute element={<TaxNumberPage />} />} />
        <Route path="/fis-basligi-bilgileri" element={<ProtectedRoute element={<VoucherInformationsPage />} />} />
        <Route path="/firma-yetkili-bilgileri" element={<ProtectedRoute element={<AuthorizedInformationPage />} />} />
        <Route path="/departmanlar" element={<ProtectedRoute element={<DepartmentsPage />} />} />
        <Route path="/cihazlarim" element={<ProtectedRoute element={<MyDevicesPage />} />} />
        <Route path="/yazar-kasalar" element={<ProtectedRoute element={<CashRegisters />} />} />
        <Route path="/kurulacak-cihazlar" element={<ProtectedRoute element={<DeviceToBeInstalledPage />} />} />
        <Route path="/fatura-bilgileri" element={<ProtectedRoute element={<BillingInformationsPage />} />} />
        <Route path="/kurulum-raporu" element={<ProtectedRoute element={<InstallationReportPage />} />} />
        <Route path="/satis-sorgu-raporu" element={<ProtectedRoute element={<SalesInquiryReportPage />} />} />
        {/* <Route path="/satis-sorgu-raporu" element={<ProtectedRoute element={<SalesInquiryReportPageAlternative />} />} /> */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
