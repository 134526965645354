import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Keypad from '../../components/Keypad';
import PageTop from '../../components/PageTop';
import api from '../../services/api';
import { cleanPhoneFormat, toAdvancedTitleCase, toTurkishLowerCase  } from '../../utils/function.js';
import { refreshSession, getCleanedValueJSON } from '../../utils/function.js';


const AuthorizedInformation = () => {
  const [nameSurname, setNameSurname] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [email, setEmail] = useState('');
  const [yetkiliBayi, setYetkiliBayi] = useState('');
  const [bayiTelefonu, setBayiTelefonu] = useState('');
  const [focusedInput, setFocusedInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [readOnly, setReadOnly] = useState(false);
  const [companyTypes, setCompanyTypes] = useState(localStorage.getItem('company_type') || '0');
  const [param, setParam] = useState(null);

  const navigate = useNavigate();
  const nameInputRef = useRef(null);
  const mobileInputRef = useRef(null);

  const isFormValid = () => {
      const validateNameSurname = () => {
        setErrorMessage(''); 
        const words = nameSurname.trim().split(/\s+/);
        if (words.length < 2) {
          setErrorMessage('Lütfen adınızı ve soyadınızı en az iki kelime olarak giriniz.');
          return false;
        }
    
        const forbiddenWords = ['bey', 'hn', 'hnm', 'hanım', 'hanim'];
        const forbiddenWordUsed = words.find(word => {
          const lowerCaseWord = toTurkishLowerCase(word);
          return forbiddenWords.includes(lowerCaseWord);
        });
        if (forbiddenWordUsed) {
          setErrorMessage(`Lütfen adınızı ve soyadınızı, unvan eklemeksizin kimlik bilgilerinizde göründüğü şekilde giriniz. '${forbiddenWordUsed}' gibi kelimeleri kullanmayınız.`);
          return false;
        }
  
        return true;
      };
    
      const validatePhone = () => {
        setErrorMessage(''); 
        const cleanPhone = cleanPhoneFormat(mobilePhone);
        if (cleanPhone.length < 10) {
          setErrorMessage('Lütfen telefon numaranızı kontrol ediniz.');
          return false;
        }
        return true;
      }

      return validateNameSurname() && validatePhone();
  };
  
  useEffect(() => {
    if (companyTypes === '1') {
      mobileInputRef.current?.focus();
    } else {
      nameInputRef.current?.focus();
    }
  }, [companyTypes]);

  const handleNavigate = () => {
    if (isFormValid()) {
      localStorage.setItem('full_name', nameSurname);
      const cleanPhone = cleanPhoneFormat(mobilePhone);
      localStorage.setItem('mobile_phone', cleanPhone);
      localStorage.setItem('email', email);
      localStorage.setItem('yetkili_bayi', yetkiliBayi);
      const cleanPhoneYetkiliBayi = cleanPhoneFormat(bayiTelefonu);
      localStorage.setItem('bayi_telefonu', cleanPhoneYetkiliBayi);
      navigate('/departmanlar');
    } else {
    }
  };

  const handleInputFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const formatPhoneNumber = (digits) => {
    digits = digits.replace(/[^\d]/g, '');
    if (digits.startsWith('0')) { 
      digits = digits.substring(1);
    }
    if (digits.length < 4) {
      return digits;
    } else if (digits.length < 7) {
      return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    } else {
      return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
    }
  };
  

  const handlePhoneInputChange = (event) => {
    const digits = event.target.value.replace(/[^\d]/g, '');
    if (digits.length > 0 && digits[0] !== '5') {
      setErrorMessage('Telefon numarası 5 ile başlamalıdır.');
      return;
    } else if (digits.length > 10) {
      // setErrorMessage('Telefon numarası en fazla 10 haneli olmalıdır.');
      return; 
    } else {
      setErrorMessage('');
    }
    const formattedNumber = formatPhoneNumber(digits);
    setMobilePhone(formattedNumber);
  };

  useEffect(() => {
    const handleResize = () => {
        setReadOnly(window.innerWidth <= 768); 
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, []);


  const fetchUserInfo = async (param) => {
    const userId = localStorage.getItem('userId');
    const companyTypes = localStorage.getItem('company_type');
    // const accountLegalName = localStorage.getItem('account_legal_name');

    const account_legal_name_ls = localStorage.getItem('account_legal_name');
    const accountLegalName = getCleanedValueJSON(JSON.parse(account_legal_name_ls));

    try {
      const response = await api.get_user_info({
        session: userId,
        page: 1,
        limit: 10
      });

      // console.log('response', response);
      if(response.error && response.error.number == 0){
        const first_name = toAdvancedTitleCase(response.first_name);
        const last_name = toAdvancedTitleCase(response.last_name);
        setYetkiliBayi(`${first_name} ${last_name}`);
        let formattedPhone;
        if(response.phone_mobile){
          formattedPhone = formatPhoneNumber(response.phone_mobile);
          setBayiTelefonu(formattedPhone);
        }
      } else if(response.error.number === '10'){
        await refreshSession(); 
        return fetchUserInfo(param);
      }

      if(param === 'all'){
        if (companyTypes === '1') {
          const newValue = (accountLegalName);
          const formattedName = toAdvancedTitleCase(newValue);

    
          setNameSurname(formattedName);
        } 
      } else if(param === 'yetkili'){
        
      }

    } catch (err) {
      console.error('hata', err);
    }
  };

  useEffect( () => {
     const storedMobilePhone = localStorage.getItem('mobile_phone');
     const storedNameSurname = localStorage.getItem('full_name');
     const storedEmail = localStorage.getItem('email');
     

    const fetchInitialData = async () => {
      if (storedMobilePhone && storedNameSurname) {
        await fetchUserInfo('yetkili');
        if (storedMobilePhone != null) {
          const formattedPhone = formatPhoneNumber(storedMobilePhone);
          setMobilePhone(formattedPhone);
        } 
        if (storedNameSurname != null) {
          setNameSurname(storedNameSurname);
        }
        if (storedEmail != null) {
          setEmail(storedEmail);
        }
      } else {
        await fetchUserInfo('all');
      }
    };

    fetchInitialData();

    const storedOfficePhone = localStorage.getItem('office_phone');
    if (storedOfficePhone && storedOfficePhone.startsWith('5')) {
      setMobilePhone(formatPhoneNumber(storedOfficePhone));
    } 
  }, []);


  const handleNameSurnameChange = (event) => {
    const newValue = toTurkishLowerCase(event.target.value);
    const formattedName = toAdvancedTitleCase(newValue);
    setNameSurname(formattedName);
  };


  return (
    <div className='page-wrapper container'>
      <div className='page-top'>
          <PageTop 
              backBtnValue={"Geri"}
              title={"Firma Yetkili Bilgileri"}
          />
          <div className='page-content'>
            <h6 className='page-desc page-desc-n'>
                Gelirler İdaresi Başkanlığı <b>tebliği gereğince</b> yetkili isim ve cep telefonu bilgilerini girilmelidir.
            </h6>
            <Input 
              value={nameSurname} 
              onFocus={() => handleInputFocus('nameSurname')}
              onChange={handleNameSurnameChange} 
              placeholder="Ad Soyad"
              autoFocus={companyTypes !== '1'}
              input_trim
            />
            <Input 
                value={mobilePhone} 
                input_type={'tel'}
                onChange={handlePhoneInputChange}
                placeholder="Cep telefonu"
                autoFocus={companyTypes === '1'}
            />
            <h6 className='page-desc page-desc-n text-left'>
              Kurulum sonrası hoşgeldin mesajı ve kullanım bilgilerini göndermek için E-posta adresi girin.
            </h6>
            <Input 
              value={email} 
              input_type='email'
              onChange={(e) => setEmail(e.target.value)} 
              onFocus={() => handleInputFocus('email')}
              placeholder="E-Posta"
              optional="true"
            />
            {errorMessage && <div className='error-message'>{errorMessage}</div>}
          </div>
      </div>
      <div className='page-bottom'>
          <div className='btn-wrapper'>
              <Button value="Devam" viewType="btn-blue" onClick={handleNavigate} />
          </div>
          {/* <Keypad onInput={handleKeypadInput} /> */}
      </div>

    </div>
  );
};

export default AuthorizedInformation;
