import CryptoJS from 'crypto-js';

// export const hashPassword = (password) => {
//     return CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
// };

export const decryptPassword = (hashedPassword) => {
    const key = CryptoJS.enc.Utf8.parse('1234567890123456');
    const iv = CryptoJS.enc.Utf8.parse('1234567890123456');

    const decrypted = CryptoJS.AES.decrypt(hashedPassword, key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
};

export const encryptPassword = (password) => {
    const key = CryptoJS.enc.Utf8.parse('1234567890123456'); 
    const iv = CryptoJS.enc.Utf8.parse('1234567890123456');

    const encrypted = CryptoJS.AES.encrypt(password, key, { iv: iv });
    return encrypted.toString();
};