import React from 'react';
import './style.css';
import IconButton from '../IconButton'; 

const DeviceToBeInstalled = ({ onDelete, deviceNumber }) => {
  return (
    <div className="single-device-install-item">
      <IconButton
        iconClass="trash-icon"
        onClick={onDelete} 
      />
      <div className='device-number'>
        <span>
          { deviceNumber }
        </span>
      </div>
    </div>
  );
};

export default DeviceToBeInstalled;
