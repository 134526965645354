import React, { useState, useEffect, useRef } from 'react';
import './style.css';
const Select = ({ options, id, placeholder, required, value, onChange, locked, textCenter }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const ref = useRef(null);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isOpen && ref.current && !ref.current.contains(e.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        }
    }, [isOpen]);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelect = (option) => {
        onChange({ target: { value: option } }); 
        setIsOpen(false);
    };

    const filteredOptions = options.filter(option =>
        typeof option === 'string' && option.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={`select-wrapper form-floating mb-2 custom-select-container ${required ? 'required' : ''} ${locked ? 'locked' : ''} `}  ref={ref}>
            <input
                type="text"
                className={`form-control ${isOpen ? 'show' : ''}  ${textCenter === 'true' ? 'text-center' : ''}  `}
                id={id}
                value={value || searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={placeholder}
                onClick={() => setIsOpen(!isOpen)}
                readOnly
                
            />
            <label htmlFor={id}>
                {placeholder}
            </label>
            {isOpen && (
                <ul className="custom-options">
                    {filteredOptions.map((option, index) => (
                        <li key={index} onClick={() => handleSelect(option)}>
                            {option}
                        </li>
                    ))}
                </ul>
            )}
            {required && <div className='required-txt'>
                Zorunlu
            </div>}
        </div>
    );
};

export default Select;
