import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input/index.js';
import Textarea from '../../components/Textarea/index.js';
import Button from '../../components/Button/index.js';
import Select from '../../components/Select/index.js';
import IconButton from '../../components/IconButton/index.js';
import provincesData from '../../datas/provinces.json';
import PageTop from '../../components/PageTop/index.js';
import { cleanPhoneFormat, displayCurrentDateTime, cleanTextProvince, reduceSpaces, toTurkishUpperCase, handleSaveToLocalStorage, toAdvancedTitleCase, toAdvancedTitleCase2, toAdvancedTitleCaseShippingAddress, simplifyTaxOfficeName, simplifyCompanyName } from '../../utils/function.js';

const VoucherInformations = () => {
  const [accountName, setAccountName] = useState('');
  const [accountLegalName, setAccountLegalName] = useState('');
  const [accountLegalNameShort, setAccountLegalNameShort] = useState('');
  const [shippingAddress, setShippingAddress] = useState('');
  const [officePhone, setOfficePhone] = useState('');
  const [province, setProvince] = useState('');
  const [plateCode, setPlateCode] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [districts, setDistricts] = useState([]);
  const [billingCity, setBillingCity] = useState('');
  const [billingCountry, setBillingCountry] = useState('');
  const [taxOffice, setTaxOffice] = useState('');
  const [taxOfficeCode, setTaxOfficeCode] = useState('');
  const [taxOfficeFull, setTaxOfficeFull] = useState('');
  const [invoiceTime, setInvoiceTime] = useState('');
  const [modalDisplay, setModalDisplay] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [lineErrorMessage, setLineErrorMessage] = useState('');
  const [logoLines, setLogoLines] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [legalName, setLegalName] = useState('');
  const [vknNo, setVknNo] = useState('');
  const [accountLegalNameLineCount, setAccountLegalNameLineCount] = useState(0);
  const [shippingAddressLineCount, setShippingAddressLineCount] = useState(0);

  const navigate = useNavigate();
  
  
  const formatLogoLines = () => {
    return new Promise((resolve) => {
      const lines = [];

      if (accountName.length != 0) lines.push(accountName);
      for (const accountLegalNameRow of accountLegalName.split('\n').slice(0, 2)) lines.push(accountLegalNameRow)
      let locationInfo = wrapTextByCharLimit(`${shippingAddress} ${toTurkishUpperCase(billingCountry)}, ${toTurkishUpperCase(province)}`);
      if (locationInfo.split('\n').reverse()[0].length > 32) locationInfo = wrapTextByCharLimit(`${shippingAddress} ${toTurkishUpperCase(billingCountry)}, ${toTurkishUpperCase(province)}`);
      let desc = lines.length >= 3 ? 1 : 0;
      if (lines.length == 1) {
        if (locationInfo.split('\n').reverse()[0].includes(`${toTurkishUpperCase(billingCountry)}, ${toTurkishUpperCase(province)}`)) {
          for (const locationInfoRow of locationInfo.split('\n').slice(0, 4)) lines.push(locationInfoRow);
        } else {
          for (const shippingAddressRow of shippingAddress.split('\n').slice(0, 3)) lines.push(shippingAddressRow);
          lines.push(`${toTurkishUpperCase(billingCountry)}, ${toTurkishUpperCase(province)}`);
        }
      } else if (locationInfo.split('\n').length >= 3) {
        let shippingAddressLength = shippingAddress.split('\n').length >= 3 ? 2 - desc : shippingAddress.split('\n').length - 1;
        if (shippingAddress.split('\n')[shippingAddressLength].length + billingCountry.length + province.length > 32) {
          for (const shippingAddressRow of shippingAddress.split('\n').slice(0, 2 - desc)) lines.push(shippingAddressRow);
          lines.push(`${toTurkishUpperCase(billingCountry)}, ${toTurkishUpperCase(province)}`);
        } else {
          let vshippingAddress = `${shippingAddress.split('\n').slice(0, 3 - desc).join('\n')} ${toTurkishUpperCase(billingCountry)}, ${toTurkishUpperCase(province)}`;
          for (const shippingAddressRow of vshippingAddress.split('\n')) lines.push(shippingAddressRow);
        }

      } else {
        let locationInfo0 = wrapTextByCharLimit(`${shippingAddress} ${toTurkishUpperCase(billingCountry)}, ${toTurkishUpperCase(province)}`);
        if (locationInfo0.split('\n').reverse()[0].length > 32) {
          locationInfo0 = wrapTextByCharLimit(`${shippingAddress} ${toTurkishUpperCase(billingCountry)}, ${toTurkishUpperCase(province)}`);
          for (const shippingAddressRow of shippingAddress.split('\n').slice(0, 2)) lines.push(shippingAddressRow);
          lines.push(`${toTurkishUpperCase(billingCountry)}, ${toTurkishUpperCase(province)}`);
        } else {
          if (locationInfo0.split('\n').reverse()[0].includes(`${toTurkishUpperCase(billingCountry)}, ${toTurkishUpperCase(province)}`)) {
            for (const locationInfoRow of locationInfo0.split('\n').slice(0, 2)) lines.push(locationInfoRow);
          } else {
            for (const shippingAddressRow of shippingAddress.split('\n').slice(0, 1)) lines.push(shippingAddressRow);
            lines.push(`${toTurkishUpperCase(billingCountry)}, ${toTurkishUpperCase(province)}`);
          }
        }

      }
      
      for (let i = 0; i < 5; i++) if (!lines[i]) lines[i] = '';
      lines.push(toTurkishUpperCase(taxOffice).trim().slice(0, 32));
      lines.push(vknNo);
      
      if (officePhone.length != 0) {
        let single_exists = -1;
        for (let i = 0; i < 5; i++) {
          if (lines[i] != `${toTurkishUpperCase(billingCountry)}, ${toTurkishUpperCase(province)}`) continue;
          single_exists = i;
          break;
        }
        const officePhoneValue = `TEL: ${officePhone}`;
        if (single_exists != -1) {
          if (lines[single_exists].length + 1 + officePhoneValue.length <= 32) {
            lines[single_exists] = `${lines[single_exists]} ${officePhoneValue}`;
          } else if (lines[single_exists + 1].length == 0) {
            lines[single_exists + 1] = officePhoneValue;
          }
        } else {
          for (let i = 0; i < 5; i++) {
            if (!lines[i].includes(`${toTurkishUpperCase(billingCountry)}, ${toTurkishUpperCase(province)}`)) continue;
            if (lines[i + 1].length == 0) lines[i + 1] = officePhoneValue;
            break;
          }
        }
        
      }
      
      
      const logoLines = {};
      for (let i = 0; i < lines.length; i++) {
        logoLines[`logo_line_${i+1}`] = lines[i];
      }
      setLogoLines(logoLines);
      resolve(logoLines);
    });
  };
 
  useEffect(() => {
    formatLogoLines();
  }, [accountName, accountLegalName, shippingAddress, officePhone, billingCountry, province, taxOffice, taxOfficeCode]);

  const toggleModal = () => {
    if(validateLineCounts()){
      formatLogoLines();
      setModalDisplay(!modalDisplay);
    }
  };

  const handleProvinceChange = (event) => {
    const newProvince = event.target.value;
    setProvince(newProvince);

    const provinceData = provincesData.find(item => item.city_name === newProvince);

    if (provinceData) {
      setPlateCode(provinceData["city_code"]); 
      const districts = provincesData
        .filter(item => item.city_name === provinceData.city_name)
        .map(item => ({ label: item.district_name ?? 'Tanımsız İlçe', value: item.district_name ?? 'bulunamadı' }));
      setDistricts(districts);
      setBillingCity(provinceData.city_name);
    } else {
      setPlateCode('');
      setDistricts([]);
    }
    setBillingCountry('');
  };

  const handleDistrictChange = (e) => {
    setBillingCountry(e.target.value);

    const districtData = provincesData.find(item => item.district_name === e.target.value && item.city_name === province);
    if (districtData) {
      setCountryCode(districtData["district_code"]); 
    } else {
      setCountryCode('');
    }
  };

  useEffect(() => {
    if (province) {
      const filteredDistricts = provincesData.filter(item => item.city_name === province);
      const districtOptions = filteredDistricts.map(item => item.district_name);
      setDistricts(districtOptions);
    } else {
      setDistricts([]);
    }
    
  }, [province]);
  
  useEffect(() => {
    if (plateCode) {
      const filteredDistricts = provincesData.filter(item => item["city_code"] === plateCode);
      setDistricts(filteredDistricts.map(district => district["district_name"]));
    } else {
      setDistricts([]);
    }
  }, [plateCode]);

  const validateLineCounts = () => {
    const validateLegalNameLineCount = accountLegalNameLineCount < 3;
    const validateShippingAddressLineCount = shippingAddressLineCount < 4;
    let validationAllow;

    if(validateLegalNameLineCount && validateShippingAddressLineCount){
      setLineErrorMessage('');
      validationAllow = true;
    } else if(!validateLegalNameLineCount && validateShippingAddressLineCount){
      setLineErrorMessage('Ticari ünvan en fazla iki satır olabilir.');
      validationAllow = false;
    } else if(!validateShippingAddressLineCount && validateLegalNameLineCount){
      setLineErrorMessage('Adres en fazla üç satır olabilir.');
      validationAllow = false;
    } else if(!validateLegalNameLineCount && !validateShippingAddressLineCount) {
      setLineErrorMessage('Ticari ünvan en fazla iki satır ve adres en fazla üç satır olabilir.');
      validationAllow = false;
    } else {
      setLineErrorMessage('Ticari ünvan en fazla iki satır ve adres en fazla üç satır olabilir.');
      validationAllow = false;
    }
    return validationAllow;
  }

  const handlePhoneInputChange = (event) => {
    const newPhoneValue = formatPhoneNumber(event.target.value);
    setOfficePhone(newPhoneValue);
  };

  const formatPhoneNumber = (value) => {
    if (!value) return '';
    const digits = value.replace(/\D/g, '');
    if (digits.length <= 3) {
      return digits;
    }
    if (digits.length <= 6) {
      return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    }
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  };

  const isFormValid = () => {
    return accountLegalName && shippingAddress && province && billingCountry;
  };

  const handleNavigate = async () => {
    const logoLinesResult = formatLogoLines();

    const cleanPhone = officePhone ? cleanPhoneFormat(officePhone) : '';
    const accountNameValue = accountName || '';
    const accountLegalNameValue = accountLegalName || '';
    const shippingAddressValue = shippingAddress || '';
    const provinceValue = province || '';
    const billingCountryValue = billingCountry || '';
    const taxOfficeValue = taxOffice || '';
    const taxOfficeCodeValue = taxOfficeCode || '';
    const invoiceTimeValue = invoiceTime || '';
    const accountLegalNameShortValue = accountLegalNameShort || '';
    const legalNameValue = legalName || '';
    const companyTypesValue = companyTypes || '';
    const plateCodeValue = plateCode || '';
    const districtCodeValue = countryCode || '';

    
    if (officePhone && cleanPhone.length < 10) {
      setErrorMessage('Lütfen telefon numaranızı kontrol ediniz.');
      return false;
    }
    if (isFormValid() && validateLineCounts()) {
      handleSaveToLocalStorage('account_name', accountNameValue);
      localStorage.setItem('account_legal_name', JSON.stringify(accountLegalNameValue));
      localStorage.setItem('shipping_address', JSON.stringify(shippingAddressValue));
      handleSaveToLocalStorage('office_phone', cleanPhone);
      handleSaveToLocalStorage('shipping_city', provinceValue);
      handleSaveToLocalStorage('shipping_county', billingCountryValue);
      handleSaveToLocalStorage('tax_office', taxOfficeValue);
      handleSaveToLocalStorage('tax_office_code', taxOfficeCodeValue);
      handleSaveToLocalStorage('invoice_time', invoiceTimeValue);
      handleSaveToLocalStorage('account_legal_name_short', accountLegalNameShortValue);
      handleSaveToLocalStorage('legal_name', legalNameValue);
      handleSaveToLocalStorage('company_type', companyTypesValue);
      handleSaveToLocalStorage('plate_code', plateCodeValue);
      handleSaveToLocalStorage('country_code', districtCodeValue);
      handleSaveToLocalStorage('vkn', vknNo);
      
      setErrorMessage('');
      setLineErrorMessage('');
      navigate('/firma-yetkili-bilgileri');
    } else if(!isFormValid()){
      setErrorMessage('Lütfen zorunlu alanları doldurunuz.');
    } else {
      setErrorMessage('');
    }

  };


  // useEffect(() => {
  //   const gibInfoRaw = localStorage.getItem('gib_info');
  //   const gibInfo = gibInfoRaw ? JSON.parse(gibInfoRaw) : {};
  //   console.log('Gib Info:', gibInfo);
  
  //   if (gibInfo.adresBilgileri && gibInfo.adresBilgileri.length > 0) {
  //     const adres = gibInfo.adresBilgileri[0];
  //     if (adres && adres.mahalleSemt) {
  //       console.log('Mahalle/Semt:', adres.mahalleSemt);
  //     } else {
  //       console.error('Mahalle/Semt bilgisi eksik!');
  //     }
  //   } else {
  //     console.error('Adres bilgileri eksik veya boş!');
  //   }
  // }, []);
  
  useEffect(() => {
    const account_name = localStorage.getItem('account_name');
    if (account_name != null) {
      handleTextChange(toAdvancedTitleCase(account_name), setAccountName);
    }

    const office_phone = localStorage.getItem('office_phone');
    if(officePhone != null){
      setOfficePhone(formatPhoneNumber(office_phone));
    }
      
    const gibInfoRaw = localStorage.getItem('gib_info');
    const gibInfo = gibInfoRaw ? JSON.parse(gibInfoRaw) : {};
    
    
    const account_legal_name = localStorage.getItem('account_legal_name');
    if(account_legal_name != null){
      const formattedName = (account_legal_name);
      // handleTextChange(toAdvancedTitleCase2(formattedName), setAccountLegalName);
      setAccountLegalName(JSON.parse(formattedName));
    } else {
      const account_legal_name = gibInfo.kimlikUnvani ? simplifyCompanyName(gibInfo.kimlikUnvani) : '';
      setAccountLegalName((account_legal_name));
      const formattedName = (account_legal_name);
      handleTextChange(toAdvancedTitleCase2(formattedName), setAccountLegalName);
    }

    const company_type = gibInfo.sirketinTuru ? gibInfo.sirketinTuru : '';
    if(company_type === '1'){
      setCompanyTypes('1');
    } else {
      setCompanyTypes('0')
    }

    const shipping_address_local = localStorage.getItem('shipping_address');
    const plate_code_local = localStorage.getItem('plate_code');
    const country_code_local = localStorage.getItem('country_code');

    const shipping_county_local = localStorage.getItem('shipping_county');
    if(shipping_address_local !== null){
      // handleTextChange(shipping_address_local, setShippingAddress);
      setShippingAddress(JSON.parse(shipping_address_local));
      if (plate_code_local) {
        setPlateCode(plate_code_local);
        const provinceData = provincesData.find(item => item["city_code"] === plate_code_local);
        if (provinceData) {
          setBillingCity(provinceData.city_name);
          setProvince(provinceData.city_name);
          const districtOptions = provincesData.filter(item => item.city_name === provinceData.city_name)
            .map(item => ({ label: item.district_name, value: item.district_name }));
          setDistricts(districtOptions);
        }
      }
      if (country_code_local) {
        const districtData = provincesData.find(item => item["district_code"] === country_code_local);
        if (districtData) {
          setBillingCountry(districtData.district_name);
        }
      } else if (shipping_county_local) {
        setBillingCountry(shipping_county_local);
      }
    } else if (gibInfo.adresBilgileri && gibInfo.adresBilgileri.length > 0 ) {      
      const adresBilgileri = gibInfo.adresBilgileri;
      const selectAdresTipi2 = (adresBilgileri) => {
        if(company_type === '1'){
          const addressType2 = adresBilgileri.find(adres => adres.adresTipi === "");
          return addressType2 ? addressType2 : adresBilgileri.find(adres => adres.adresTipi === "1");
        } else {
          const addressType2 = adresBilgileri.find(adres => adres.adresTipi === "");
          return addressType2 ? addressType2 : adresBilgileri.find(adres => adres.adresTipi === "1");
        }
      };
      const addressInfo = selectAdresTipi2(adresBilgileri);
      const shipping_address_mahalleSemt = toAdvancedTitleCaseShippingAddress(addressInfo.mahalleSemt || '');
      const shipping_address_koy = toAdvancedTitleCaseShippingAddress(addressInfo.koy || '');
      const shipping_address_caddeSokak = toAdvancedTitleCaseShippingAddress(addressInfo.caddeSokak || '');
      const shipping_address_disKapiNo = toAdvancedTitleCaseShippingAddress(addressInfo.disKapiNo || '');
      const shipping_address_icKapiNo = toAdvancedTitleCaseShippingAddress(addressInfo.icKapiNo || '');
      const shipping_address_beldeBucak = toAdvancedTitleCaseShippingAddress(addressInfo.beldeBucak || '');
      const fullAddress = `${shipping_address_mahalleSemt} ${shipping_address_caddeSokak}  ${shipping_address_disKapiNo} ${shipping_address_icKapiNo} ${shipping_address_beldeBucak} ${shipping_address_koy}`;
      if (fullAddress) {
        handleTextChange(toAdvancedTitleCaseShippingAddress(fullAddress), setShippingAddress);
      }
      
      const city_code = (addressInfo.ilKodu || '');
      const country_code = (addressInfo.ilceKodu || '');
      setCountryCode(country_code);
      const city_code_provinces = provincesData.filter(item => item["city_code"] === addressInfo.ilKodu);
      const shipping_city = city_code_provinces[0]?.["city_name"] ?? 'Belirtilmemiş İl';
      setProvince(shipping_city);
      setPlateCode(city_code);

      const district_code = cleanTextProvince(addressInfo.ilceKodu);
      const country_code_provinces = provincesData.filter(item => item["district_code"] === district_code);
      const district_name = country_code_provinces[0]?.["district_name"] ?? '';
      setBillingCountry(district_name);

    } else {
      setShippingAddress('');
      setBillingCity('');
      setDistricts([]);
      setBillingCountry('');
    }

    
    if (gibInfo.vergiDairesiKodu) {
      const tax_office = simplifyTaxOfficeName(gibInfo.vergiDairesiAdi);
      setTaxOffice(tax_office);
      setTaxOfficeCode(gibInfo.vergiDairesiKodu);
      setVknNo(gibInfo.vkn);
      // const vkn = localStorage.getItem('vkn');
      // const tckn = localStorage.getItem('tckn');
      // let no = (vkn.length > 0) ? vkn : tckn;
      setTaxOfficeFull(`${tax_office} ${gibInfo.vkn}`);
    } else {
      setTaxOffice('');
      setTaxOfficeCode('');
      setTaxOfficeFull('');
    }
    

    if (gibInfo.unvan) {
      const unvan = gibInfo.unvan;
      setLegalName(unvan);
    } else {
      setLegalName('');
    }

    setInvoiceTime(displayCurrentDateTime());

    setAccountLegalNameShort(account_legal_name)
  }, []);

  const handleTextChange = (text, setter) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = '';

    const correctAcronym = (word) => {
      return word
        .split('.')
        .map(part => part.length > 0 ? toTurkishUpperCase(part.charAt(0)) + part.slice(1).toLowerCase() : part)
        .join('.');
    };

    words.forEach(word => {

      if (word.includes('.')) {
        word = correctAcronym(word);
      }
      
      if (word.length > 32) {
        if (currentLine !== '') {
          lines.push(currentLine);
          currentLine = '';
        }
        while (word.length > 32) {
          lines.push(word.slice(0, 32));
          word = word.slice(32);
        }
        currentLine = word;
      } else if (currentLine.length + word.length + 1 > 32) {
        if (currentLine !== '') {
          lines.push(currentLine);
        }
        currentLine = word;
      } else {
        if (currentLine === '') {
          currentLine = word;
        } else {
          currentLine += ' ' + word;
        }
      }
    });

    if (currentLine !== '') {
        lines.push(currentLine); 
    }

    const formattedText = lines.join('\n');
    setter(formattedText);
};


  useEffect(() => {
    if (Object.keys(logoLines).length > 0) {
      handleSaveToLocalStorage('logoLines', JSON.stringify(logoLines));
    }
  }, [logoLines]); 


  function wrapTextByCharLimit(text, charLimit = 32) {
      text = text.replace(/\s*\n\s*/g, '\n').trim();
      text = text.replace(/\s{2,}/g, ' ');

      let result = [];
      const lines = text.split('\n');

      lines.forEach(line => {
          if (!line) {
              result.push('');
          } else {
              while (line.length > 0) {
                  if (line.length <= charLimit) {
                      result.push(line);
                      break;
                  }

                  let cutPoint = line.lastIndexOf(' ', charLimit);
                  if (cutPoint === -1 || cutPoint === 0) cutPoint = charLimit;

                  result.push(line.substring(0, cutPoint));

                  line = line.substring(cutPoint).trimStart();
              }
          }

          if (!line && result[result.length - 1] !== '') {
              result.push('');
          }
      });

      return result.join('\n').trim();
  }

  const handleAccountNameChange = (event) => {
    setAccountName(event.target.value);
  };
  
  const handleAccountNameBlur = (event) => {
    const formatted = reduceSpaces(accountName);
    setAccountName(formatted)
  };

  useEffect(() => {
    setAccountLegalNameLineCount(accountLegalName.split('\n').length); 
    setShippingAddressLineCount(shippingAddress.split('\n').length); 
  }, [accountLegalName, shippingAddress])

  const handleAccountLegalNameBlur = (e) => {
    const { value } = e.target;
    const wrappedText = wrapTextByCharLimit(value);
    setAccountLegalName((wrappedText)); 

    setAccountLegalNameLineCount(wrappedText.split('\n').length); 
  };

  const handleAccountLegalNameChange = (event) => {
    const formattedName = toAdvancedTitleCase(event.target.value);
    setAccountLegalName(event.target.value);
  };

  const handleShippingAddressChange = (event) => {
    setShippingAddress(event.target.value);
  };

  const handleShippingAddressBlur = (e) => {
    const { value } = e.target;
    const wrappedText = wrapTextByCharLimit(value);
    setShippingAddress((wrappedText)); 
    
    setShippingAddressLineCount(wrappedText.split('\n').length); 
  };

  const handleTextareaChange = (e, setter, lineSetter) => {
    const { value } = e.target;
    setter(value);

    const lineCount = value.split('\n').length;
    lineSetter(lineCount);
  };



  return (
    <div className='page-wrapper container'>
      <div className='page-top'>
          <PageTop
              backBtnValue={"Geri"}
              title={"Fiş Başlığı Bilgileri"}
          />

          <div className='page-content'>
            { modalDisplay === false && (
              <>
                <Input
                  maxLength={32}
                  value={accountName}
                  onChange={handleAccountNameChange}
                  onBlur={handleAccountNameBlur}
                  placeholder="Tabela Adı"
                  autoFocus={true}
                  textCenter="true"
                  input_trim
                />
                <Textarea
                  value={accountLegalName}
                  onChange={handleAccountLegalNameChange}
                  onBlur={handleAccountLegalNameBlur}
                  placeholder="Ticari Ünvan"
                  textCenter="true"
                  input_trim
                />
                <Textarea
                  value={shippingAddress}
                  onChange={handleShippingAddressChange}
                  onBlur={handleShippingAddressBlur}
                  placeholder="Cihaz Kullanım Adresi"
                  textCenter="true"
                  input_trim
                />
                {lineErrorMessage && <div className='error-message'>{lineErrorMessage}</div>}
                <Input
                  value={officePhone}
                  input_type={'tel'}
                  optional={'true'}
                  onChange={handlePhoneInputChange}
                  placeholder="İşyeri Telefonu"
                />
                <Select
                  value={province} 
                  onChange={handleProvinceChange}
                  options={provincesData
                    .map(item => item.city_name)
                    .filter((v, i, a) => a.indexOf(v) === i)
                    .sort((a, b) => a.localeCompare(b, 'tr-TR'))}
                  placeholder="İl Seçiniz"
                  id="province-select"
                  textCenter="true"
                />
                <Select
                  key={province} 
                  value={billingCountry}
                  onChange={handleDistrictChange}
                  options={districts}
                  placeholder="İlçe Seçiniz"
                  id="district-select"
                  textCenter="true"
                />
                <Input
                  value={taxOfficeFull}
                  onChange={(e) => setTaxOfficeFull(e.target.value)}
                  placeholder="Vergi Dairesi ve Nosu"
                  textCenter="true"
                  locked="true"
                  readOnly={true}
                  iconName={'locked'}
                  bgTransparent="true"
                />
                {errorMessage && <div className='error-message'>{errorMessage}</div>}
              </>
            )}
            {modalDisplay && (
              <div className="invoice-modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-body">
                      {/* {accountName} */}
                      {Object.entries(logoLines).map(([key, value], index, array) => {
                        if (index === array.length - 2) {
                          return (
                            <div key={key} style={{ display: 'flex', justifyContent: 'center', gap: '.5rem' }}>
                              <p>{value}</p>
                              <p>{array[index + 1][1]}</p>  
                            </div>
                          );
                        } else if (index < array.length - 2) {
                          return <p key={key}>{value}</p>;
                        }
                        return null;
                      })}
                      {/* <p>{accountLegalNameShort}</p>
                      <p>{shippingAddress}</p>
                      {officePhone && (
                          <p>TEL: {phoneFormatted(officePhone)}</p>
                      )}
                      <p>{billingCountry}</p>
                      <p>{province}</p>
                      <p>{taxOffice}</p>
                      <p>{taxOfficeCode}</p> */}

                      <div className='invoice-info'>
                        <span className='left'>
                          FİŞ NO:0000
                        </span>
                        <span className='right'>
                          {invoiceTime}
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
      </div>
      <div className='page-bottom'>
        <div className='btn-wrapper'>
          <Button value="Devam" viewType="btn-blue"  onClick={handleNavigate} />
          <IconButton
            iconClass="invoice-icon"
            onClick={toggleModal}
            viewType="btn-white"
            className={modalDisplay ? "pressed" : ""}
          />
        </div>
      </div>

    </div>
  );
};

export default VoucherInformations;
