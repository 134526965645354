import React from 'react';
import './style.css';

const SingleDevice = ({ deviceNumber, deviceTxt, deviceDate, onClick, isChoosed }) => {
  return (
    <div className={`single-device-item ${isChoosed ? 'choosed' : ''}`}  onClick={onClick}>
      <div className='device-number'>
        <span>
          { deviceNumber }
        </span>
      </div>
      <div className='device-txt'>
        <span>
          { deviceTxt }
        </span>
      </div>
      <div className='device-date'>
          <span>
            { deviceDate }
          </span>
      </div>
    </div>
  );
};

export default SingleDevice;
