import React from 'react';
import './style.css';

const Key = ({ value, onClick }) => {
    const isBackspace = value === 'x';
    const keyMap = {
      '0': '0', '1': '1', '2': '2', '3': '3', '4': '4', 
      '5': '5', '6': '6', '7': '7', '8': '8', '9': '9', 
      '.00': '.', 'x': 'Backspace'
    };
    return (
      <button className="key" data-key={keyMap[value]} onMouseDown={() => onClick(value)}>
        {isBackspace ? <span className={'backspace-icon'} /> : value}
      </button>
    );
  };

  

const Keypad = ({ onInput }) => {
    const handleKeyPress = (value) => {
      onInput(value);
    };
    return (
      <div className='keypad-wrapper'>
        <div className="keypad">
          {['1', '2', '3', '4', '5', '6', '7', '8', '9', 'C', '0', 'x'].map(num => (
            <Key key={num} value={num} onClick={handleKeyPress} />
          ))}
        </div>
      </div>
    );
  };
  

export default Keypad;
