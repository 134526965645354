import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import SingleDevice from '../../components/SingleDevice';
import IconButton from '../../components/IconButton';
import api from '../../services/api';
import PageTop from '../../components/PageTop';
import { refreshSession } from '../../utils/function.js';

const MyDevices = () => {
  const [devices, setDevices] = useState([]);
  const [clickedDevices, setClickedDevices] = useState(() => {
    const storedDevices = localStorage.getItem('clickedDevices');
    return storedDevices ? JSON.parse(storedDevices) : [];
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [allLoaded, setAllLoaded] = useState(false);
  const navigate = useNavigate();
  const devicesRef = useRef(null);

  const formatDate = (dateTime) => {
    const [year, month, day] = dateTime.split(' ')[0].split('-');
    return `${day}-${month}-${year}`;
  };

  const fetchDevices = async (currentPage) => {
    if (allLoaded || loading) return;
  
    setLoading(true); 
    const userId = localStorage.getItem('userId');

    try {
      const response = await api.get_user_info({
        session: userId,
        page: currentPage,
        limit: 12
      });

      if (response.error.number === "0" && response.get_ecr_list) {
        let newItems = response.get_ecr_list.item;
        newItems = Array.isArray(newItems) ? newItems : [newItems];
        
        const updatedItems = newItems.filter(newItem => 
          !devices.some(device => device.id === newItem.id)
        );

        if (updatedItems.length > 0) {
          setDevices(prevDevices => [...prevDevices, ...updatedItems]);
          setCurrentPage(currentPage + 1);
        }  else {
          setAllLoaded(true);
        }
      } else if(response.error.number === '10'){
        await refreshSession();  
        return fetchDevices(currentPage);
      } else {
        setAllLoaded(true);
      }
    } catch (err) {
      setError('Serviste hata oluştu');
    }finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDevices(currentPage);
  }, []);

  const handleScroll = () => {
    const devicesDiv = devicesRef.current;
    if (devicesDiv.scrollHeight - devicesDiv.scrollTop <= devicesDiv.clientHeight + 1 && !loading && !allLoaded) {
      fetchDevices(currentPage);
    }
  }

  useEffect(() => {
    const devicesDiv = devicesRef.current;
    devicesDiv.addEventListener('scroll', handleScroll);

    return () => {
      devicesDiv.removeEventListener('scroll', handleScroll);
    };
  }, [loading, allLoaded, currentPage]);

  const handleDeviceClick = (device) => {
    setClickedDevices(prevClickedDevices => {
      const isAlreadyClicked = prevClickedDevices.some(clickedDevice => clickedDevice.id === device.id);
      const updatedDevices = isAlreadyClicked 
        ? prevClickedDevices.filter(clickedDevice => clickedDevice.id !== device.id)
        : [...prevClickedDevices, device];

      localStorage.setItem('clickedDevices', JSON.stringify(updatedDevices));
      return updatedDevices;
    });
  };

  const handleNavigate = () => {
    if (clickedDevices.length === 0) {
      setError('Lütfen devam etmeden önce en az bir cihaz seçiniz.');
    } else {
      setError('');
      navigate('/kurulacak-cihazlar');
    } 
   
  };

  return (
    <div className='page-wrapper container'>
      <div className='page-top'>
        <PageTop 
            backBtnValue={"Geri"}
            title={"Yazarkasalarım"}
        />
        <div className='page-content'>
          <div className='devices' ref={devicesRef} style={{ height: '60vh', overflowY: 'auto' }}>
            {devices.map((device) => (
              <SingleDevice
                key={device.id}
                deviceNumber={device.name}
                deviceDate={formatDate(device.last_update_date)}
                onClick={() => handleDeviceClick(device)}
                isChoosed={clickedDevices.some(clickedDevice => clickedDevice.id === device.id) ? 'clicked' : ''}
              />
            ))}
            {loading && <div className='loading'></div>}
            {devices.length === 0 && !loading && !error && <p>Cihaz bulunamadı.</p>}
          </div>
          <div className='text-center'>
            <p className='text-sm mt-2'>
              Kurulabilir cihaz listenizi güncellemek için ekranı aşağıya doğru kaydırın
            </p>
          </div>
          {error && <div className='error-message'>{error}</div>}
        </div>
      </div>

      <div className='page-bottom'>
        <div className='btn-wrapper'>
          <Button value="Devam" viewType="btn-blue" onClick={handleNavigate} />
          <IconButton
            onClick={handleNavigate} 
            iconClass="basket-icon"
            viewType="btn-white" 
            basketNumber={clickedDevices.length}
          />
        </div>
      </div>
    </div>
  );
};

export default MyDevices;
