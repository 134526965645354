import React from 'react';
import './style.css';

const IconButton = ({ iconClass, onClick, viewType, className, basketNumber }) => {
  return (
    <button
      onClick={onClick} 
      className={`btn icon-btn ${viewType} ${className}`} 
    >
      <span className={iconClass}></span>
      {basketNumber > 0 && <div className='basket-number'>
        {basketNumber}
      </div>}
    </button>
  );
};

export default IconButton;