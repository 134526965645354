import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import { turkishToUpperCase } from '../../utils/function.js';

const SingleCashRegister = ({ order, name, isSelected, onClick, onNameChange, maxLength, isOptional }) => {
  const [inputValue, setInputValue] = useState(name || '');
  const inputRef = useRef(null);
  const [firstFocus, setFirstFocus] = useState(true);

  useEffect(() => {
    if (isSelected) {
      inputRef.current.focus();
      if (firstFocus && !name) {
        setInputValue('FT400');
        setFirstFocus(false);
      }
    } else {
      setFirstFocus(true);
      setInputValue(name || '');
    }
  }, [isSelected, name]);

  const handleEdit = () => {
    onClick(order);
    if (firstFocus && !name) {
      setInputValue('FT400');
      setFirstFocus(false);
    }
  };

  const handleBlur = () => {
    onNameChange(inputValue);
    if (inputValue === 'FT400' && !name) {
      setInputValue('');
    }
  };

  const handleChange = (event) => {
    const newValue = turkishToUpperCase(event.target.value);
    setInputValue(newValue);
    if (newValue !== 'FT400') {
      setFirstFocus(false);
    }
  };

  return (
    <div 
      className={`cash-register ${isSelected ? 'selected clicked' : ''}`} 
      onClick={handleEdit}
    >
      <div className="register-info">
        <span className="register-order">{order}</span>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control register-name"
              id={`floatingInput-${order}`}
              value={inputValue}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Sicil No"
              ref={inputRef}
              maxLength={maxLength}
              inputMode='numeric'
            />
            <label htmlFor={`floatingInput-${order}`}>Sicil No</label>
          </div>
          {isOptional && <span className="optional-label">Opsiyonel</span>}
      </div>
    </div>
  );
};

export default SingleCashRegister;
