import api from '../services/api'; 
import { decryptPassword } from './hash';
  
export function turkishToUpper (str) {
    const letters = {
      'ğ': 'Ğ',
      'ü': 'Ü',
      'ş': 'Ş',
      'ı': 'I',
      'i': 'İ',
      'ö': 'Ö',
      'ç': 'Ç',
      'a': 'A',
      'b': 'B',
      'c': 'C',
      'd': 'D',
      'e': 'E',
      'f': 'F',
      'g': 'G',
      'h': 'H',
      'j': 'J',
      'k': 'K',
      'l': 'L',
      'm': 'M',
      'n': 'N',
      'o': 'O',
      'p': 'P',
      'r': 'R',
      's': 'S',
      't': 'T',
      'u': 'U',
      'v': 'V',
      'y': 'Y',
      'z': 'Z'
    };
    return str.replace(/[\wıiüğşöç]/g, char => letters[char] || char.toUpperCase());
  };


  
  export function getCurrentFormattedTime() {
    const now = new Date();
    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const year = now.getFullYear();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year}, ${hours}:${minutes}`;
  }

  export function splitTextIntoLines(text, maxChars) {
    const words = text.split(' ');
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
        if (currentLine.length + words[i].length + 1 <= maxChars) {
            currentLine += ' ' + words[i];
        } else {
            lines.push(currentLine.replace(/\n/g, '').trimEnd());
            currentLine = words[i];
        }
    }

    if (currentLine) {
        lines.push(currentLine.replace(/\n/g, ' ').trimEnd());
    }

    return lines;
};
  
  
export function validateTCKN(value) {
    if (!value || value.length !== 11) return false;
    const isEleven = /^[0-9]{11}$/.test(value);
    if (!isEleven) return false;

    let totalX = 0;
    for (let i = 0; i < 10; i++) {
        totalX += Number(value[i]);
    }
    const isRuleX = totalX % 10 === Number(value[10]);

    let totalY1 = 0;
    let totalY2 = 0;
    for (let i = 0; i < 10; i += 2) {
        totalY1 += Number(value[i]);
    }
    for (let i = 1; i < 9; i += 2) {
        totalY2 += Number(value[i]);
    }
    const isRuleY = ((totalY1 * 7) - totalY2) % 10 === Number(value[9]);

    return isRuleX && isRuleY;
}


export function toTurkishLowerCase(str) {
  if (!str) return '';

  const turkishLowerMap = {
    'İ': 'i', 'I': 'ı', 'Ş': 'ş', 'Ğ': 'ğ',
    'Ü': 'ü', 'Ö': 'ö', 'Ç': 'ç'
  };

  const toTurkishLower = (char) => {
    if (char in turkishLowerMap) {
      return turkishLowerMap[char];
    }
    return char.toLowerCase();
  };

  return str
    .split('')
    .map(char => toTurkishLower(char))
    .join('');
}



export function capitalizeWords(input) {
    if (!input) return ''
    return input.split(' ')
        .map(word => toTurkishLowerCase(word))
        .map(word => {
            if (word === "ve") {
                return "ve";
            } else {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
        })
        .join(' ');
}

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

// "VERGİ DAİRESİ MÜD." -> "V.D."
export function simplifyTaxOfficeName(name) {
    let simplified = name.replace('VERGİ DAİRESİ', 'V.D.');
    simplified = simplified.replace(' MÜD.', ''); 
    return simplified
      .split(' ')
      .map(word => {
          if (word === "V.D.") {
              return word;
          } else {
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          }
      })
      .join(' ');
}

// ozel kısaltmalar
export function simplifyCompanyName(name) {
  const shortReplacements = {
    "LİMİTED ŞİRKETİ": "LTD. ŞTİ.",
    "LIMITED SIRKETI": "LTD. ŞTİ.",
    "SANAYİ VE TİCARET": "SAN. TİC.",
    "SANAYI VE TICARET": "SAN. TİC.",
    "ANONİM ŞİRKETİ": "A.Ş.",
    "ANONIM SIRKETI": "A.Ş.",
  };

  let shortName = name;
  for (const [key, value] of Object.entries(shortReplacements)) {
    const regex = new RegExp(key, 'gi');
    shortName = shortName.replace(regex, value);
  }

  if (shortName.length < 32) return shortName;

  let words = shortName.split(' ');

  const firstThreeWords = [];
  let count = 0;
  for (let word of words) {
    if (word.length > 2 || word.toUpperCase() !== 'VE') {
      firstThreeWords.push(word);
      count++;
      if (count === 3) break;
    } else {
      firstThreeWords.push(word);
    }
  }

  const remainingWords = words.slice(firstThreeWords.length).map(word => 
    word.length > 3 ? word.slice(0, 3) + '.' : word
  );
  shortName = [...firstThreeWords, '', ...remainingWords].join(' ');

  if (shortName.length >= 32) {
    shortName = shortName.replaceAll('. ', '.');
  }

  shortName = shortName.replace(/ (?=\w+\.)/g, '');

  return shortName;
}


export async function refreshSession() {
  const username = localStorage.getItem('username');
  const encryptedPassword = localStorage.getItem('password');
  
    if (username && encryptedPassword) {
      try {
        const password = decryptPassword(encryptedPassword);
        const response = await api.login ({
              user_name: username,
              password: password
          });
          console.log('response', response);

          if (response && response.error && response.error.number === '10') {
              console.log('Hata', response.error.message);
              localStorage.removeItem('userId');
          } else if (response && response.error && response.error.number === '0') {
              localStorage.setItem('userId', response.id);
              console.log('Session yenilendi');
          }
      } catch (error) {
          console.log('Session yenilenirken hata', error.message);
          localStorage.removeItem('userId');
      }
  } else {
      localStorage.removeItem('userId');
  }
}

export function turkishToLowerCase(str) {
  const turkishCharMap = {
    'I': 'ı',
    'İ': 'i',
    'Ş': 'ş',
    'Ğ': 'ğ',
    'Ü': 'ü',
    'Ö': 'ö',
    'Ç': 'ç'
  };
  return str.replace(/[IİŞĞÜÖÇ]/g, match => turkishCharMap[match]);
}

export function turkishToUpperCase(str) {
  const turkishCharMap = {
    'i': 'İ',
    'ı': 'I',
    'ş': 'Ş',
    'ğ': 'Ğ',
    'ü': 'Ü',
    'ö': 'Ö',
    'ç': 'Ç'
  };
  let upperStr = str.toUpperCase();

  upperStr = upperStr.replace(/I/g, 'İ').replace(/İ/g, 'I'); 

  return upperStr.replace(/[iışğüöç]/g, match => turkishCharMap[match.toLowerCase()]);
}

const cleanText = (text) => {
  if (!text) return '';
  return text.replace(/\s\s+/g, ' ');
};

export function toAdvancedTitleCase2(str) {
  if (!str) return '';

  str = str.replace(/\n+/g, '\n');
  str = str.replace(/\s+/g, ' ');
  
  
  const lowerCaseWords = new Set(["ve", "ile", "veya"]);
  const turkishUpperMap = {
    'i': 'İ', 'ı': 'I', 'ş': 'Ş', 'ğ': 'Ğ',
    'ü': 'Ü', 'ö': 'Ö', 'ç': 'Ç'
  };
  const turkishLowerMap = {
    'İ': 'i', 'I': 'ı', 'Ş': 'ş', 'Ğ': 'ğ',
    'Ü': 'ü', 'Ö': 'ö', 'Ç': 'ç'
  };

  const toTurkishUpperCase = (char) => {
    if (char in turkishUpperMap) {
      return turkishUpperMap[char];
    }
    return char.toUpperCase();
  };

  const toTurkishLowerCase = (char) => {
    if (char in turkishLowerMap) {
      return turkishLowerMap[char];
    }
    return char.toLowerCase();
  };

  const convertWord = (word) => {
    if (word.length === 0) return '';
    return word.split(/(\.+)/).map(part => {
      if (/^\.*$/.test(part)) return part;
      return toTurkishUpperCase(part.charAt(0)) + part.slice(1).split('').map(toTurkishLowerCase).join('');
    }).join('');
  };

  return str
    .split(/(\s+|\n+)/) 
    .map((segment, index, segments) => {
      if (segment === '\n') return segment;
      if (segment.trim() === '') return segment;
      return segment
        .split(' ')
        .map((word, wordIndex, words) => {
          if (word.length === 0) return word;
          let isFirstWord = wordIndex === 0 || words[wordIndex - 1] === ''; 
          let followsNewLine = index > 0 && segments[index - 1] === '\n';
          let isLowerCaseWord = lowerCaseWords.has(word.toLowerCase()) && !isFirstWord && !followsNewLine;
          if (isLowerCaseWord) {
            return word.toLowerCase();
          } else if (word === word.toUpperCase() && word.includes('.')) {
            return word.split('.').map(part => convertWord(part)).join('.');
          } else {
            return convertWord(word);
          }
        })
        .join(' ');
    })
    .join('');
}

export function toAdvancedTitleCase(str) {
  if (!str) return '';

  const lowerCaseWords = new Set(["ve", "ile", "veya"]);
  const pattern = /\b[A-Z][A-Z.]*\b/;
  const turkishCharMap = {
    'i': 'İ',
    'ı': 'I',
    'ş': 'Ş',
    'ğ': 'Ğ',
    'ü': 'Ü',
    'ö': 'Ö',
    'ç': 'Ç'
  };

  const convertTurkishChars = (char, toUpper = true) => {
    if (toUpper) {
      return turkishCharMap[char] || char.toUpperCase();
    } else {
      return Object.keys(turkishCharMap).find(key => turkishCharMap[key] === char) || char.toLowerCase();
    }
  };

  const convertWord = (word) => {
    if (word.length === 0) return '';
    let firstChar = convertTurkishChars(word.charAt(0));
    let rest = word.slice(1).split('').map(char => convertTurkishChars(char, false)).join('');
    return firstChar + rest;
  };


  const cleanedStr = str.trimStart().replace(/\s+/g, ' ');;

  return cleanedStr
    .split(' ')
    .map((word, index) => {
      if (pattern.test(word)) {
        return word;
      } else if (lowerCaseWords.has(word.toLowerCase()) && index !== 0) {
        return word.toLowerCase();
      } else {
        return convertWord(word);
      }
    })
    .join(' ');
}


export function toAdvancedTitle_Case(str) {
  if (!str) return '';

  const lowerCaseWords = new Set(["ve", "ile", "veya"]);
  const turkishCharMapUpper = {
    'i': 'İ', 'ı': 'I', 'ş': 'Ş', 'ğ': 'Ğ',
    'ü': 'Ü', 'ö': 'Ö', 'ç': 'Ç'
  };
  
  const turkishCharMapLower = {
    'İ': 'i', 'I': 'ı', 'Ş': 'ş', 'Ğ': 'ğ',
    'Ü': 'ü', 'Ö': 'ö', 'Ç': 'ç'
  };

  const convertTurkishChars = (char, toUpper = true) => {
    if (toUpper) {
      return turkishCharMapUpper[char.toLowerCase()] || char.toUpperCase();
    } else {
      return turkishCharMapLower[char] || char.toLowerCase();
    }
  };
  
  const convertWord = (word) => {
    if (word.length === 0) return '';
    if (word === word.toUpperCase()) return word; 
    let firstChar = convertTurkishChars(word.charAt(0), true);
    let rest = word.slice(1).split('').map(char => convertTurkishChars(char, false)).join('');
    return firstChar + rest;
  };

  const cleanedStr = str.trimStart().replace(/\s+/g, ' ');

  return cleanedStr
    .split(' ')
    .map((word, index) => {
      if (word === word.toUpperCase()) {
        return word; 
      } else if (lowerCaseWords.has(word.toLowerCase()) && index !== 0) {
        return word.toLowerCase();
      } else {
        return convertWord(word);
      }
    })
    .join(' ');
}




export function toAdvancedTitleCaseShippingAddress(str) {
  if (!str) return '';

  const lowerCaseWords = new Set(["ve", "ile", "veya"]);
  const turkishUpperMap = {
    'i': 'İ', 'ı': 'I', 'ş': 'Ş', 'ğ': 'Ğ',
    'ü': 'Ü', 'ö': 'Ö', 'ç': 'Ç'
  };
  const turkishLowerMap = {
    'İ': 'i', 'I': 'ı', 'Ş': 'ş', 'Ğ': 'ğ',
    'Ü': 'ü', 'Ö': 'ö', 'Ç': 'ç'
  };

  const toTurkishUpperCase = (char) => {
    if (char in turkishUpperMap) {
      return turkishUpperMap[char];
    }
    return char.toUpperCase();
  };

  const toTurkishLowerCase = (char) => {
    if (char in turkishLowerMap) {
      return turkishLowerMap[char];
    }
    return char.toLowerCase();
  };

  const convertWord = (word) => {
    if (word.length === 0) return '';
    let firstChar = toTurkishUpperCase(word.charAt(0));
    let rest = word.slice(1).split('').map(toTurkishLowerCase).join('');
    return firstChar + rest;
  };


  const cleanText = (text) => {
    return text.replace(/\s\s+/g, ' ');
  };

  const cleanedStr = cleanText(str);

  return cleanedStr
    .split(' ')
    .map((word, index) => {
      if (lowerCaseWords.has(word.toLowerCase()) && index !== 0) {
        return word.toLowerCase();
      } else {
        return convertWord(word);
      }
    })
    .join(' ');
}


export function handleSaveToLocalStorage(key, value) {
  const cleanedValue = value.replace(/\n/g, ' '); 
  localStorage.setItem(key, cleanedValue);
};

export function getCleanedValueJSON(value){
  const cleanedValue = value.replace(/\n/g, ' ').trim();
  return cleanedValue;
}

export function reduceSpaces(text) {
  return text.replace(/\s+/g, ' ').trim();
}

export function toTurkishUpperCase(str) {
  if (!str) return '';

  const turkishUpperMap = {
    'i': 'İ', 'ı': 'I', 'ş': 'Ş', 'ğ': 'Ğ',
    'ü': 'Ü', 'ö': 'Ö', 'ç': 'Ç'
  };

  const toTurkishUpper = (char) => {
    if (char in turkishUpperMap) {
      return turkishUpperMap[char];
    }
    return char.toUpperCase();
  };

  return str
    .split('')
    .map(char => toTurkishUpper(char))
    .join('')
    .replace(/İ̇/g, 'İ');
}

export function toAdvancedTitleCaseLegalName(str) {
  if (!str) return '';

  const lowerCaseWords = new Set(["ve", "ile", "veya"]);
  const turkishUpperMap = {
    'i': 'İ', 'ı': 'I', 'ş': 'Ş', 'ğ': 'Ğ',
    'ü': 'Ü', 'ö': 'Ö', 'ç': 'Ç',
    'i̇': 'İ' 
  };
  const turkishLowerMap = {
    'İ': 'i', 'I': 'ı', 'Ş': 'ş', 'Ğ': 'ğ',
    'Ü': 'ü', 'Ö': 'ö', 'Ç': 'ç'
  };

  const toTurkishUpperCase = (char) => {
    if (char in turkishUpperMap) {
      return turkishUpperMap[char];
    }
    return char.toUpperCase();
  };

  const toTurkishLowerCase = (char) => {
    if (char in turkishLowerMap) {
      return turkishLowerMap[char];
    }
    return char.toLowerCase();
  };

  const convertWord = (word) => {
    if (word.length === 0) return '';
    let firstChar = toTurkishUpperCase(word.charAt(0));
    let rest = word.slice(1).split('').map(toTurkishLowerCase).join('');
    return firstChar + rest;
  };

  return str
  .split(' ')
  .map((word, index) => {
    if (word.endsWith('.') && word.slice(0, -1).split('.').every(part => part.toUpperCase() === part)) {
      return word;
    } else if (lowerCaseWords.has(word.toLowerCase()) && index !== 0) {
      return word.toLowerCase();
    } else {
      return convertWord(word);
    }
  })
  .join(' ');
}

export function cleanAndSplitText(text, maxChars) {
  if (!text) return '';

  const cleanedText = text.replace(/\s\s+/g, ' ');

  let currentLine = '';
  let finalText = '';

  cleanedText.split(' ').forEach(word => {
    if ((currentLine + ' ' + word).trim().length <= maxChars) {
      currentLine += (currentLine ? ' ' : '') + word;
    } else {
      finalText += (finalText ? ' \n' : '') + currentLine;
      currentLine = word;
    }
  });

  if (currentLine.length > 0) {
    finalText += (finalText ? ' \n' : '') + currentLine;
  }

  return finalText.split(' \n').map(line => toTurkishUpperCase(line).slice(0, maxChars));
};

export function toTurkishTitleCase(str) {
  if (!str) return '';

  const turkishUpperMap = {
    'i': 'İ', 'ı': 'I', 'ş': 'Ş', 'ğ': 'Ğ',
    'ü': 'Ü', 'ö': 'Ö', 'ç': 'Ç'
  };

  const toTurkishUpper = (char) => {
    if (char in turkishUpperMap) {
      return turkishUpperMap[char];
    }
    return char.toUpperCase();
  };

  return str.split(' ').map(word => {
    return toTurkishUpper(word.charAt(0)) + word.slice(1);
  }).join(' ');
}

const cleanTextSpecial = (str) => {
  return str.trim().replace(/\s+/g, ' ');
};

export function toAdvancedTitleCaseSpecial(str) {
  if (!str) return '';

  const lowerCaseWords = new Set(["ve", "ile", "veya"]);
  const turkishUpperMap = {
    'i': 'İ', 'ı': 'I', 'ş': 'Ş', 'ğ': 'Ğ',
    'ü': 'Ü', 'ö': 'Ö', 'ç': 'Ç'
  };
  const turkishLowerMap = {
    'İ': 'i', 'I': 'ı', 'Ş': 'ş', 'Ğ': 'ğ',
    'Ü': 'ü', 'Ö': 'ö', 'Ç': 'ç'
  };

  const toTurkishUpperCase = (char) => {
    if (char in turkishUpperMap) {
      return turkishUpperMap[char];
    }
    return char.toUpperCase();
  };

  const toTurkishLowerCase = (char) => {
    if (char in turkishLowerMap) {
      return turkishLowerMap[char];
    }
    return char.toLowerCase();
  };

  const convertWord = (word) => {
    if (word.length === 0) return '';
    let firstChar = toTurkishUpperCase(word.charAt(0));
    let rest = word.slice(1).split('').map(toTurkishLowerCase).join('');
    return firstChar + rest;
  };

  const cleanedStr = cleanTextSpecial(str);

  return cleanedStr
    .split(' ')
    .map((word, index) => {
      if (lowerCaseWords.has(word.toLowerCase()) && index !== 0) {
        return word.toLowerCase();
      } else if (word === word.toUpperCase() && word.endsWith('.')) {
        return word;
      } else {
        return convertWord(word);
      }
    })
    .join(' ');
}

export function cleanPhoneFormat(formattedPhone) {
  return formattedPhone.replace(/[()\- ]/g, '');
};

export function displayCurrentDateTime(){
  var now = new Date();
  var day = ("0" + now.getDate()).slice(-2);
  var month = ("0" + (now.getMonth() + 1)).slice(-2);
  var year = now.getFullYear();
  var hours = ("0" + now.getHours()).slice(-2);
  var minutes = ("0" + now.getMinutes()).slice(-2);
  return day + '-' + month + '-' + year + ' ' + hours + ':' + minutes;
}

export function cleanTextProvince(text){
  if (typeof text === 'string') {
    return text.trim().toLowerCase();
  }
  return text;
};

export function toLowerCase(text){
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    .replace(/ğ/g, "g")
    .replace(/Ğ/g, "G")
    .replace(/ç/g, "c")
    .replace(/Ç/g, "C")
    .replace(/ş/g, "s")
    .replace(/Ş/g, "S")
    .replace(/ı/g, "i")
    .replace(/İ/g, "I")
    .replace(/ö/g, "o")
    .replace(/Ö/g, "O")
    .replace(/ü/g, "u")
    .replace(/Ü/g, "U")
    .toLowerCase();
}
